<template>
  <main class="home">
    <div class="projects" v-if="!loading">
        <template v-for="project in output">
          <div :key="project.uid" class="project">
            <div @click="openGallery(project.uid, 1)">
                <span class="title">{{ project.title }}</span>
                <span v-if="project.description">, {{ project.description }}</span>
                <span v-if="project.year">, {{ project.year }}</span>
            </div>
            <div class="project-files">
              <template v-for="(file, index) in project.files">
                <div
                  v-if="file.extension === 'jpg' && $store.state.types.images"
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, index + 1)"
                >
                  {{ project.num }}.{{ index + 1 }}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb">
                    <img
                        loading="lazy"
                      :src="file.thumb"
                      :srcset="file.srcset"
                      sizes="50vw"
                      :alt="project.title"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    file.extension === 'mp4' ||
                    (file.extension === 'mov' && $store.state.types.video)
                  "
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, index + 1)"
                >
                  {{ project.num }}.{{ index + 1 }}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb preview">
                    Video<br />
                    {{ file.caption }}
                  </div>
                </div>
                <div
                  v-if="
                    file.extension === 'pdf' && $store.state.types.documents
                  "
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, index + 1)"
                >
                  {{ project.num }}.{{ index + 1}}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb preview">
                    Document<br />
                    {{ file.caption }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
    </div>
    <div class="projects" v-else>
        ...
    </div>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </main>
</template>

<script>
export default {
  data() {
    return {
        loading: true,
    };
  },
  created() {
    if (this.$store.state.year.length > 0) {
      this.loading = false;
    }
  },
  computed: {
    output() {
        return this.$store.state.year;
    },
  },
  mounted() {
    this.$root.$on("projects-loaded", () => {
      this.loading = false;
    });
 
  },
  methods: {
    openGallery(uid, slideId) {
      this.$router.push({
        name: "project",
        params: { id: uid },
        hash: "#" + slideId,
      });
      this.$store.state.settings = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
//   transform: translate3d(0, 100vh, 0);
  opacity:0;
}
.projects {
  width: 100%;
  box-sizing: border-box;
  min-height: 50%;
  column-count: 1;
  column-gap: calc(4 * var(--lh));
  @media only screen and (max-width: 768px) {
    column-count: 1;
  }
    .project {
      margin-bottom: calc(2 * var(--lh));
      .project-files {
        padding-left: calc(2 * var(--lh));
        .project-file {
          display: inline-block;
          width: calc(100% / 6);
          transition: color 0.5s;
          position: relative;
          user-select: none;
          @media only screen and (max-width:768px) {
            width: calc(100% / 4);
          }
          &:hover {
            font-style: italic;
            cursor: pointer;
            .file-thumb {
              display: block;
              &.preview {
                display: flex;
              }
            }
          }
          .file-thumb {
            display: none;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            mix-blend-mode: multiply;
            pointer-events: none;
            text-align: center;
            color: black;
            &.preview {
              align-items: center;
              justify-content: center;
              height: calc(30vw * 0.75);
              width: 30vw;
              background: rgba(0, 0, 0, 0.1);
              color: rgba(0, 0, 0, 0.8);
            }
            img {
              height: 30vw;
              width: 30vw;
              object-fit: contain;
            //   filter: grayscale(100%);
              @media only screen and (max-width: 1024px) {
                width: 45vw;
                height: 45vw;
              }
            }
          }
          .filetype {
            vertical-align: super;
            font-size: calc(var(--fs) / 1.6);
            font-feature-settings: "c2sc", "smcp";
            font-variant: all-small-caps;
            padding-left: 0.25vw;
            letter-spacing: 0.05vw;
          }
        }
      }
    }
}
</style>
